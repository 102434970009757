<template>
  <v-menu
    v-model="menu"
    v-bind="$attrs"
    offset-y
    :close-on-content-click="false"
    content-class="overflow-auto"
    z-index="202"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- Button -->
      <v-btn class="btn" v-bind="attrs" v-on="on" text small :icon="iconOnly">
        <v-icon :left="!iconOnly" :size="iconSize || 20">
          mdi-emoticon-happy
        </v-icon>

        <template v-if="!iconOnly"> Emoji </template>
      </v-btn>
    </template>

    <div class="emoji-picker-wrapper" :id="wrapperId"></div>
  </v-menu>
</template>

<script>
import "emoji-picker-element";
import emojiReplace from "@/utils/emojiReplace";
import { Picker } from "emoji-picker-element";
import de from "emoji-picker-element/i18n/de";
import en from "emoji-picker-element/i18n/en";
import i18n from "@/plugins/i18n";

export default {
  name: "EmojiMenu",

  props: {
    value: {
      type: String,
      default: null,
    },

    autoreplace: {
      type: Boolean,
      default: true,
    },

    iconSize: String,
    iconOnly: Boolean,
  },

  data: () => ({
    menu: false,
    id: "",
    en,
    de,
    wrapperId: "",
    inited: false,
  }),

  watch: {
    menu(val) {
      if (val) {
        this.init();
      } else {
        this.$emit("close");
      }
    },

    value(str) {
      if (this.autoreplace && str && str[str.length - 1] === " ") {
        this.$emit("input", emojiReplace(str));
      }
    },
  },

  created() {
    this.id = "emoji-picker-" + new Date().getTime();
    this.wrapperId = `wrapper-${this.id}`;
  },

  methods: {
    init() {
      if (this.inited) {
        return;
      }

      this.$nextTick(() => {
        const picker = new Picker();
        picker.setAttribute("id", this.id);
        const locale = i18n.locale;
        picker.i18n = this[locale];
        document.getElementById(this.wrapperId).appendChild(picker);
        document
          .getElementById(this.id)
          .addEventListener("emoji-click", (event) => {
            this.$emit("pick", event);
            this.onClick(event.detail);
          });

        this.inited = true;
      });
    },

    onClick(emoji) {
      if (!this.value) {
        this.$emit("input", "");
        this.$nextTick(() => {
          this.$emit("input", this.value.concat(emoji.unicode));
        });
        return;
      }

      this.$emit("input", this.value.concat(emoji.unicode));
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn__content {
    font-weight: normal !important;
    font-size: 14px;
  }
}
</style>
