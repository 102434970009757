export default function (str) {
  for (let i = 0; i < data.length; ++i) {
    const e = data[i];
    const replacedStr = str.replace(e.emoticon + " ", e.emoji + " ");

    if (replacedStr !== str) {
      return replacedStr;
    }
  }

  return str;
}

const data = [
  {
    emoji: "😄",
    emoticon: ":D",
  },
  {
    emoji: "😆",
    emoticon: "XD",
  },
  {
    emoji: "🤣",
    emoticon: ":'D",
  },
  {
    emoji: "😂",
    emoticon: ":')",
  },
  {
    emoji: "🙂",
    emoticon: ":)",
  },
  {
    emoji: "😉",
    emoticon: ";)",
  },
  {
    emoji: "😊",
    emoticon: ":>",
  },
  {
    emoji: "😇",
    emoticon: "O:)",
  },
  {
    emoji: "😘",
    emoticon: ":X",
  },
  {
    emoji: "😚",
    emoticon: ":*",
  },
  {
    emoji: "😛",
    emoticon: ":P",
  },
  {
    emoji: "😜",
    emoticon: ";P",
  },
  {
    emoji: "😝",
    emoticon: "XP",
  },
  {
    emoji: "🤔",
    emoticon: ":L",
  },
  {
    emoji: "🤐",
    emoticon: ":Z",
  },
  {
    emoji: "😐️",
    emoticon: ":|",
  },
  {
    emoji: "😶",
    emoticon: ":#",
  },
  {
    emoji: "😏",
    emoticon: ":j",
  },
  {
    emoji: "😒",
    emoticon: ":?",
  },
  {
    emoji: "😬",
    emoticon: "8D",
  },
  // {
  //   emoji: "🤢",
  //   emoticon: "%(",
  // },
  {
    emoji: "🥴",
    emoticon: ":&",
  },
  // {
  //   emoji: "😵",
  //   emoticon: "XO",
  // },
  {
    emoji: "😎",
    emoticon: "8)",
  },
  {
    emoji: "🤓",
    emoticon: ":B",
  },
  {
    emoji: "😕",
    emoticon: ":/",
  },
  {
    emoji: "☹️",
    emoticon: ":(",
  },
  {
    emoji: "😲",
    emoticon: ":O",
  },
  {
    emoji: "😳",
    emoticon: ":$",
  },
  {
    emoji: "😧",
    emoticon: ":S",
  },
  {
    emoji: "😢",
    emoticon: ":'(",
  },
  {
    emoji: "😭",
    emoticon: ":'o",
  },
  {
    emoji: "😱",
    emoticon: "Dx",
  },
  {
    emoji: "😖",
    emoticon: "X(",
  },
  {
    emoji: "😓",
    emoticon: ":<",
  },
  {
    emoji: "😩",
    emoticon: "D:",
  },
  {
    emoji: "😫",
    emoticon: ":C",
  },
  {
    emoji: "😡",
    emoticon: ">:/",
  },
  {
    emoji: "🤬",
    emoticon: ":@",
  },
  {
    emoji: "😈",
    emoticon: ">:)",
  },
  {
    emoji: "👿",
    emoticon: ">:(",
  },
  // {
  //   emoji: "👹",
  //   emoticon: ">0)",
  // },
  {
    emoji: "😽",
    emoticon: ":3",
  },
  {
    emoji: "💔",
    emoticon: "</3",
  },
  {
    emoji: "❤️",
    emoticon: "<3",
  },
  {
    emoji: "🤘",
    emoticon: "\\M/",
  },
  // {
  //   emoji: "🧙‍♂️",
  //   emoticon: ":{>",
  // },
  // {
  //   emoji: "🧛",
  //   emoticon: ":E",
  // },
  // {
  //   emoji: "🧟",
  //   emoticon: "8#",
  // },
];
