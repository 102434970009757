<template>
  <div class="plyr-wrapper rounded-lg overflow-hidden">
    <slot name="prepend" />

    <video class="plyr" ref="video">
      <source :src="src" type="video/mp4" />
      <source :src="src" type="video/webm" />
      <source :src="src" type="video/ogg" />
      Sorry, your browser doesn't support embedded videos.
    </video>

    <slot name="append" />
  </div>
</template>

<script>
import Plyr from "plyr";
import "plyr/dist/plyr.css";

export default {
  name: "Plyr",

  props: {
    src: String,
    options: Object,
  },

  data: () => ({
    plyr: null,
    defaultOptions: {},
  }),

  mounted() {
    this.initPlyr();
  },

  methods: {
    initPlyr() {
      this.plyr = new Plyr(this.$refs.video, {
        ...this.defaultOptions,
        ...this.options,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
